<template>
  <v-tooltip
    right
    v-if="url"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-dialog
        v-model="dialog"
        hide-overlay
        max-width="450"
        :disabled="externalUrlIgnore"
      >
        <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
          <v-btn
            :href="externalUrlIgnore ? url : null"
            :target="externalUrlIgnore ? '_blank' : null"
            class="caption text-decoration-none pa-0"
            color="primary"
            text
            v-bind="{...attrs, ...dialogAttrs}"
            v-on="{...on, ...dialogOn}"
          >
            <v-icon dark>
              {{ url.match(/.*\.pdf\??.*$/) ? 'mdi-file-pdf-box' : 'mdi-link' }}
            </v-icon>
            <span class="text-lowercase">{{ url.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img)[0].replace(/https?:\/\//, '') }}</span>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            {{ $t('dialogs.externalUrlConfirm.title') }}
          </v-card-title>
          <v-card-text>
            <TextareaSafeDisplay :value="$t('dialogs.externalUrlConfirm.text')" />
            <v-checkbox
              v-model="skipConfirmation"
              dense
              hide-details
              :label="$t('dialogs.externalUrlConfirm.buttons.ignore')"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog = false"
            >
              {{ $t('dialogs.externalUrlConfirm.buttons.stay') }}
            </v-btn>
            <v-btn
              color="error"
              target="_blank"
              :href="url"
              @click="onContinue"
            >
              {{ $t('dialogs.externalUrlConfirm.buttons.continue') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <span>
      {{ url }}
    </span>
  </v-tooltip>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextareaSafeDisplay from '@/components/common/TextareaSafeDisplay'

export default {
  components: {
    TextareaSafeDisplay
  },
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialog: false,
      skipConfirmation: false
    }
  },
  computed: {
    ...mapGetters({
      externalUrlIgnore: 'setting/externalUrlIgnore'
    })
  },
  methods: {
    ...mapActions([
      'setting/updateExternalUrlIgnore'
    ]),
    onContinue () {
      this['setting/updateExternalUrlIgnore'](this.skipConfirmation)
      this.dialog = false
    }
  }
}
</script>

<style>

</style>
