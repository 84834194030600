<template>
  <div
    id="scrolling-screening-detail"
    @scroll="onScroll"
    style="width: 100%;"
  >
    <v-card
      class="mt-4 mx-4 px-6 pt-3"
      :loading="requestLoading"
      :disabled="requestFailed"
    >
      <v-row>
        <v-col
          cols="9"
          class="pt-0"
        >
          <!-- General Info -->
          <v-row v-if="isCorporateEntity || isIndividualEntity">
            <v-card-title
              class="pb-0"
            >
              {{ $t('dialogs.screeningDetail.general._title') }}
            </v-card-title>
            <v-card-text>
              <table>
                <tr
                  v-for="(field, key) in (
                    isCorporateEntity
                    ? ['businessName'] : (
                      isIndividualEntity
                      ? ['title', 'firstName', 'middlename', 'lastName', 'gender', 'country', 'dateOfBirth', 'dateOfDeath']
                      : []
                    )
                  )"
                  height="30px"
                  :key="key"
                >
                  <td width="200px">
                    <span>
                      {{ $t(`dialogs.screeningDetail.general.${field}.label`) }}
                    </span>
                  </td>
                  <td>
                    <span class="primary--text font-weight-bold">
                      {{ data[field] || '-' }}
                    </span>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-row>
          <!-- Contact Info -->
          <v-row v-if="isCorporateEntity || isIndividualEntity">
            <v-card-title
              class="pb-0"
            >
              {{ $t(`dialogs.screeningDetail.contact.title`) }}
            </v-card-title>
            <v-card-text>
              <table>
                <tr
                  v-for="(field, key) in (
                    isCorporateEntity
                    ? ['businessPhone', 'fax', 'website']
                    : (
                      isIndividualEntity
                      ? ['homePhone', 'mobilePhone', 'fax', 'email']
                      : []
                      )
                  )"
                  height="30px"
                  :key="key"
                >
                  <td width="200px">
                    <span>
                      {{ $t(`dialogs.screeningDetail.contact.${field}.label`) }}
                    </span>
                  </td>
                  <td>
                    <span class="primary--text font-weight-bold">
                      {{ data[field] || '-' }}
                    </span>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-row>
          <!-- Name Info -->
          <v-row>
            <v-card-title
              class="pb-0"
            >
              {{ $t('dialogs.screeningDetail.name.title') }}
            </v-card-title>
            <v-card-text>
              <table
                width="800px"
                style="border-collapse: collapse;"
              >
                <tr
                  height="30px"
                  class="blue lighten-5"
                >
                  <td width="170px">
                    <span class="black--text spanText">
                      {{ $t('dialogs.screeningDetail.name.primaryName') }}
                    </span>
                  </td>
                  <td>
                    <span class="primary--text font-weight-bold">
                      {{ data.businessName || [data.firstName, data.middlename, data.lastName].filter(Boolean).join(' ') || '-' }}
                    </span>
                  </td>
                </tr>
                <tr
                  v-for="(_name, index) in data.names.filter((nameA, index) => data.names.findIndex(nameB => nameB.fullName.trim() === nameA.fullName.trim()) === index && nameA.fullName.trim() !== [data.firstName, data.middlename, data.lastName].filter(Boolean).join(' ').trim())"
                  :key="index"
                  height="30px"
                  :class="index%2 == 0? 'white' : 'blue lighten-5'"
                >
                  <td width="170px">
                    <span class="black--text spanText">
                      {{ index == 0 ? $t('dialogs.screeningDetail.name.aka') : '' }}
                    </span>
                  </td>
                  <td>
                    <span class="primary--text font-weight-bold">
                      {{ _name.fullName }}
                    </span>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-row>
          <!-- Address Info -->
          <v-row v-if="data && data.addresses && data.addresses.length">
            <v-card-title
              class="pb-0"
            >
              {{ $t('dialogs.screeningDetail.address.title') }}
            </v-card-title>
            <v-card-text>
              <div
                ref="addressList"
                class="__shuffle-list"
              >
                <div class="__shuffle-item addressItemSizer" />
                <div
                  class="__shuffle-item addressItem"
                  v-for="(add, index) in data.addresses"
                  :key="`address_${index}`"
                >
                  <v-card
                    class="roundBorder"
                  >
                    <v-card-text>
                      <p
                        class="primary--text mb-1"
                      >
                        {{ add.addressLine1 }}
                      </p>
                      <p
                        class="primary--text mb-1"
                      >
                        {{ add.addressLine2 }}
                      </p>
                      <p
                        class="primary--text mb-1"
                      >
                        {{ add.addressLine3 + ' ' + add.addressLine4 }}
                      </p>
                      <p
                        class="primary--text mb-1"
                      >
                        <span>{{ add.townCity }} </span>
                        <span v-if="add.townCity"> &amp; </span>
                        <span> {{ add.countyState }} </span>
                      </p>
                      <p
                        class="primary--text font-weight-bold mb-1"
                      >
                        {{ add.country }}
                      </p>
                      <p v-if="add.softDelete == 'Y'">
                        ({{ $t('dialogs.screeningDetail.softDelete') }})
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </v-row>
          <!-- Associations -->
          <v-row v-if="data && data.associations && data.associations.length">
            <v-card-title
              class="pb-0"
            >
              {{ $t('dialogs.screeningDetail.association.title') }}
            </v-card-title>
            <v-card-text>
              <div
                ref="associationList"
                class="__shuffle-list"
              >
                <div class="__shuffle-item associationItemSizer" />
                <div
                  class="__shuffle-item associationItem"
                  v-for="(association, index) in data.associations"
                  :key="`association_${index}`"
                >
                  <v-card
                    class="roundBorder"
                  >
                    <v-card-text>
                      <p
                        class="primary--text font-weight-bold mb-1"
                      >
                        {{
                          (association.name || []).concat([{ type: 'placeholder', fullName: 'N/A' }]).sort((a, b) => {
                            return ['Primary Name', 'Also Known As', 'placeholder'].indexOf(a.type) - ['Primary Name', 'Also Known As', 'placeholder'].indexOf(b.type)
                          })[0].fullName
                        }}
                      </p>
                      <small
                        class="text-capitalize"
                      >
                        {{ association.associationType.toLowerCase() }}
                      </small>
                      |
                      <small
                        class="text-capitalize"
                      >
                        {{ association.associationID }}
                      </small>
                      <p
                        class="primary--text mb-1"
                      >
                        {{ association.description }}
                      </p>
                      <p v-if="association.softDelete == 'Y'">
                        ({{ $t('dialogs.screeningDetail.softDelete') }})
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </v-row>
          <!-- Notes -->
          <v-row v-if="data && data.notes && data.notes.length">
            <v-card-title
              class="pb-0"
            >
              {{ $t('dialogs.screeningDetail.note.title') }}
            </v-card-title>
            <v-card-text>
              <div
                ref="noteList"
                class="__shuffle-list"
              >
                <div class="__shuffle-item noteItemSizer" />
                <div
                  class="__shuffle-item noteItem"
                  v-for="(note, index) in data.notes"
                  :key="`note_${index}`"
                >
                  <v-card
                    class="roundBorder"
                  >
                    <v-card-text>
                      <p
                        class="primary--text font-weight-bold mb-1"
                      >
                        {{ note.source || '' }}
                      </p>
                      <p
                        class="primary--text mb-1"
                      >
                        {{ note.description || '' }}
                      </p>
                      <p v-if="note.softDelete == 'Y'">
                        ({{ $t('dialogs.screeningDetail.softDelete') }})
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
            </v-card-text>
          </v-row>
          <!-- Articles -->
          <v-row>
            <v-card-title
              class="pb-0"
            >
              {{ $t('dialogs.screeningDetail.article.title') }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-simple-table
                    fixed-header
                  >
                    <template v-slot:default>
                      <thead
                        class="blue lighten-5"
                      >
                        <tr>
                          <th />
                          <th class="text-uppercase font-weight-bold blue--text">
                            {{ $t('dialogs.screeningDetail.article.table.originalUrl') }}
                          </th>
                          <th class="text-uppercase font-weight-bold blue--text">
                            {{ $t('dialogs.screeningDetail.article.table.c6Url') }}
                          </th>
                          <th class="text-uppercase font-weight-bold blue--text">
                            {{ $t('dialogs.screeningDetail.article.table.category') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(article, index) in data.articles"
                          :key="index"
                        >
                          <td class="blue--text">
                            #{{ index + 1 }}
                          </td>
                          <td width="40%">
                            <ExternalUrl :url="article.originalUrl" />
                          </td>
                          <td width="40%">
                            <ExternalUrl :url="article.c6Url" />
                          </td>
                          <td
                            class="caption blue--text"
                            width="20%"
                          >
                            {{ article.source }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </v-col>
        <v-col
          cols="3"
        >
          <v-row>
            <v-img
              style="width: 100%;"
              class="my-2"
              :contain="data.picture ? false : true"
              :src="data.picture || defaultPortraitIcon"
              :style="data.picture ? 'border-radius: 25px;' : ''"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey"
                  />
                </v-row>
              </template>
            </v-img>
          </v-row>
          <!-- Client tag -->
          <v-row>
            <ScreeningCategoryChips :category="data.category" />
          </v-row>
          <!-- Sanction table -->
          <v-row>
            <table
              class="sanctionTable pt-4"
              v-if="data.sanctions.length > 0"
            >
              <tr>
                <th
                  class="px-3"
                >
                  <span
                    class="font-weight-bold white--text"
                  >
                    {{ $t('dialogs.screeningDetail.sanction.title') }}
                  </span>
                  <v-icon
                    v-show="data.sanctions.filter(sanction => sanction.current!='Y').length > 0"
                    color="white"
                    style="float:right;"
                    @click="showSanctionInactive = !showSanctionInactive"
                  >
                    mdi-history
                  </v-icon>
                </th>
              </tr>
              <tr
                v-for="(item, key) in data.sanctions.filter(el => showSanctionInactive ? true : el.current=='Y').sort((a, b) => {
                  return a.current > b.current ? -1 : 1
                })"
                :key="`sanctions_${key}`"
              >
                <td
                  class="px-3 py-1"
                >
                  <span
                    class="red--text"
                  >
                    {{ item.sanctionBody }}
                  </span>
                  <p
                    v-if="item.current != 'Y'"
                    class="ma-0"
                  >
                    ({{ $t('dialogs.screeningDetail.sanction.inactive') }})
                  </p>
                </td>
              </tr>
            </table>
          </v-row>
          <!-- Political Positions -->
          <v-row v-if="data.politicalPosition.length">
            <table
              class="politicalTable pt-4"
            >
              <tr>
                <th
                  class="px-3"
                >
                  <span
                    class="font-weight-bold white--text"
                  >
                    {{ $t('dialogs.screeningDetail.politicalPosition.title') }}
                  </span>
                </th>
              </tr>
              <template
                v-for="(item, index) in data.politicalPosition"
              >
                <tr
                  :key="index"
                  v-if="show ? true : index < 3"
                >
                  <td
                    class="px-3 py-2"
                  >
                    <p
                      class="blue--text font-weight-bold ma-0"
                    >
                      [ {{ item.country }} ]
                    </p>
                    <p
                      class="blue--text font-weight-bold ma-0"
                    >
                      {{ [item.from, item.to].filter(Boolean).join(' - ') }}
                    </p>
                    <p
                      class="blue--text ma-0"
                    >
                      {{ item.description }}
                    </p>
                    <p v-if="item.softDelete == 'Y'">
                      ({{ $t('dialogs.screeningDetail.softDelete') }})
                    </p>
                  </td>
                </tr>
              </template>
            </table>
            <v-row
              class="pa-0"
              justify="center"
              v-if="data.politicalPosition.length > 3"
            >
              <v-btn
                x-small
                depressed
                color="blue white--text"
                style="border-radius: 0px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px"
                plain
                @click="show = !show"
                center
              >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import DefaultPortraitIcon from '@/assets/default_portrait_picture.svg'
import Shuffle from 'shufflejs'
import ExternalUrl from '@/components/common/ExternalUrl'
import ScreeningCategoryChips from '@/components/common/ScreeningCategoryChips'

export default {
  name: 'ScreeningDetail',
  components: {
    ExternalUrl,
    ScreeningCategoryChips
    // ScreeningReport
  },
  props: {
    entityDetail: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      name: ['Wai', 'Ch8n', 'Meng', 'Adam'],
      show: true,
      requestLoading: true,
      requestFailed: false,
      data: {
        entityID: '',
        entityType: '',
        enteredDate: 0,
        updatedDate: '',
        category: [],
        country: null,
        dateOfBirth: null,
        dateOfDeath: null,
        title: null,
        picture: null,
        names: [],
        addresses: [],
        email: null,
        businessPhone: '',
        homePhone: '',
        mobilePhone: '',
        fax: '',
        associations: [],
        notes: [],
        articles: [],
        sanctions: [],
        politicalPosition: [],
        firstName: null,
        middlename: null,
        lastName: null,
        businessName: null,
        website: null
      },
      headers: [
        { text: 'Original Url', value: 'originalUrl', width: '35%', sortable: false, class: 'blue lighten-5 blue--text font-weight-bold text-uppercase' },
        { text: 'C6 Url', value: 'c6Url', width: '35%', sortable: false, class: 'blue lighten-5 blue--text font-weight-bold text-uppercase' },
        { text: 'category', value: 'source', width: '20%', sortable: false, class: 'blue lighten-5 blue--text font-weight-bold text-uppercase' }
      ],
      shuffleAddress: null,
      shuffleAssociation: null,
      shuffleNote: null,
      showSanctionInactive: false,
      openReport: false
    }
  },

  watch: {
    entityDetail: {
      deep: true,
      handler (_new, _old) {
        if (_new.entityID !== _old.entityID) {
          this.requestLoading = true
          this.requestFailed = false
          this.getScreeningResultsEntity(this.entityDetail)
            .then(res => {
              this.data = res.data
              this.requestLoading = false
              this.shuffle(true)
            })
            .catch(err => {
              this.requestFailed = true
              console.log(err)
            })
            .finally(() => {
              this.requestLoading = false
            })
        }
      }
    },
    requestLoading (_requestLoading) {
      this.$emit('update:request-loading', _requestLoading)
    },
    data: {
      deep: true,
      handler (newData) {
        this.$emit('update:data', newData)
      }
    }
  },

  methods: {
    ...mapActions('scan', [
      'getScreeningResultsEntity'
    ]),
    shuffle (destory) {
      this.$nextTick(() => {
        if (destory) {
          try {
            if (this.shuffleAddress && !this.shuffleAddress.isDestroyed) this.shuffleAddress.destroy()
            if (this.shuffleAssociation && !this.shuffleAssociation.isDestroyed) this.shuffleAssociation.destroy()
            if (this.shuffleNote && !this.shuffleNote.isDestroyed) this.shuffleNote.destroy()
          } catch (e) {
            console.log('shuffle error', e)
          }
        }

        if (this.$refs.addressList) {
          this.shuffleAddress = new Shuffle(this.$refs.addressList, {
            itemSelector: '.addressItem',
            sizer: '.addressItemSizer'
          })
        }
        if (this.$refs.associationList) {
          this.shuffleAssociation = new Shuffle(this.$refs.associationList, {
            itemSelector: '.associationItem',
            sizer: '.associationItemSizer'
          })
        }
        if (this.$refs.noteList) {
          this.shuffleNote = new Shuffle(this.$refs.noteList, {
            itemSelector: '.noteItem',
            sizer: '.noteItemSizer'
          })
        }

        if (this.shuffleAddress) this.shuffleAddress.update()
        if (this.shuffleAssociation) this.shuffleAssociation.update()
        if (this.shuffleNote) this.shuffleNote.update()
      })
    },
    onScroll () {
      this.shuffle()
      try {
        // try to trigger components recalculation
        window.dispatchEvent(new Event('resize'))
      } catch (unusedError) { }
    },
    downloadReport () {
      const routeData = this.$router.resolve({ name: 'ReportScreeningEntity', query: { } })
      this.windowRef = window.open(routeData.href, '', 'width=920,height=800,left=200,top=200')
      // this.windowRef.document.body.appendChild(this.$el)
      // copyStyles(window.document, this.windowRef.document)
      // this.windowRef.addEventListener('beforeunload', this.closePortal)
      window.childWindowReady = () => {
        this.windowRef.setReportData({ ...this.data }, this.organization_name, this.username)
      }
    }
  },

  computed: {
    ...mapGetters('auth', [
      'is_logged_in',
      'username'
    ]),
    ...mapState('auth', [
      'organization_name',
      // 'role',
      'access_menus'
    ]),
    defaultPortraitIcon () {
      return DefaultPortraitIcon
    },
    isCorporateEntity () {
      return this.data.entityType.toLowerCase() !== 'individual'
    },
    isIndividualEntity () {
      return this.data.entityType.toLowerCase() === 'individual'
    }
  },

  mounted () {
    this.requestLoading = true
    this.getScreeningResultsEntity(this.entityDetail)
      .then(res => {
        this.data = res.data
        this.requestLoading = false
        // console.log('entity detail', this.data)

        this.shuffle()
      })
  }
}
</script>

<style>

.flexcol .v-btn__content {
  flex-direction: column;
}

.v-dialog {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.v-dialog::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.spanText {
  display: inline-block;
  width: 200px;
  padding-left: 20px;
}

.roundBorder {
  border-radius: 15px !important;
}

a:hover {
  font-weight: bold !important;
}

/* Sanction table */
.sanctionTable {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 0.875rem;
}

.sanctionTable tr td {
  border-right: 5px solid #F44336;
  border-left: 5px solid #F44336;
  height: 30px
}

.sanctionTable tr th {
  background: #F44336;
  border: 2px solid #F44336;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: start;
  height: 30px;
  vertical-align:bottom;
}

.sanctionTable tr:nth-child(odd){
  background-color: #FCE4EC;
}

.sanctionTable tr:last-child td{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 5px solid #F44336;
}

/* Political Table */
.politicalTable {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  font-size: 0.875rem;
}

.politicalTable tr td {
  border-right: 5px solid #2196F3;
  border-left: 5px solid #2196F3;
  height: 30px
}

.politicalTable tr th {
  background: #2196F3;
  border: 2px solid #2196F3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: start;
  height: 30px;
  vertical-align:bottom;
}

.politicalTable tr:nth-child(odd){
  background-color: #E3F2FD;
}

.politicalTable tr:last-child td{
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 5px solid #2196F3;
}

.politicalTable tr:not([style*="display:none;"]):last-child td {
  border-bottom: 5px solid #2196F3;
}

.__shuffle-item {
  max-width: 25%;
  width: 100%;
  padding: 24px 0 0 24px;
}

.__shuffle-list {
  overflow: visible !important; margin: 0 0 0 -24px;
}
</style>
